import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, Redirect, Link } from "react-router-dom";
import {
  signIn,
  resetSignIn,
  signInGoogle,
  signInFacebook,
  signInApple,
  getRegisterOtp,
} from "../../actions/auth-action";
import { changeTimezone } from "../../actions/setting-action";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Card,
} from "react-bootstrap";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AppleLogin from "react-apple-login";
import "./Login.scss";
import { getCurrentTimeZone } from "../../utils/Utils";
import Header from "../common/HeaderGuest";
import Footer from "../common/Footer";
import queryString from "query-string";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import GoogleLoginComponent from "./GoogleLoginComponent";
import HeaderGuest from "../common/HeaderGuest";
import GuestContainer from "./GuestContainer";

const Login = (props) => {
  const {
    resetSignIn,
    signIn,
    signInGoogle,
    signInFacebook,
    signInApple,
    getData,
  } = props;
  const location = useLocation();
  const host = window.location.origin;
  const hash = window.location.hash;
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const signInStart = useSelector((state) => state.auth.signInStart);
  const { search } = useLocation();
  const { t } = useTranslation();
  const query = queryString.parse(search);
  const pathname = "/dashboard";
  const gaConfirmed = useSelector((state) => state.auth.gaConfirmed);
  const gaEnabled = useSelector((state) => state.settings.gaEnabled);

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const clientId = '99775611904-eon95ujc9qgaasgd5q9m2aulttj01op0.apps.googleusercontent.com';


  useEffect(() => {
    resetSignIn();
    return () => resetSignIn();
  }, []);
  

  useEffect(() => {
    const currentTimezone = getCurrentTimeZone();
    window.timezone = currentTimezone;
  }, []);

  const login = (e) => {
    e.preventDefault();
    signIn(email, pass);
  };

  const loginGoogle = (res) => {
    const { tokenId } = res;
    signInGoogle(tokenId);
  };

  const loginFacebook = (res) => {
    const { accessToken, userID } = res;
    signInFacebook(accessToken, userID);
  };

  const loginApple = (res) => {
    console.log("Apple", res);
  };

  const failedLoginGoogle = (res) => {
    console.log("Failed login Google", res);
  };

  useEffect(() => {
    if (hash) {
      const idTokenIndex = hash.indexOf("id_token");
      const idToken = hash.slice(idTokenIndex + 9);
      signInApple(idToken);
    }
  }, [hash]);

  if (token) {
    if (user && user.email_verified_at) {
      if (gaEnabled && !gaConfirmed) return <Redirect to="/ga/confirm" />;
      return <Redirect to={pathname} />;
    }
    return <Redirect to="/verify-otp" />;
  }

  return (
    <>
    <GuestContainer>

    <Row className="justify-content-center auth-panel">
          <Col className="auth-panel-content">
            <div className="signup-panel text-center">
              <img src="/images/named-logo.png"  className="mb-3 auth-logo"></img>
              <h2 className="mt-3 header-text mb-2">{t("log-in-header")}</h2>
              <Form>
                <Form.Group controlId="formBasicEmail" className="ch-form pos-relative mt-4">
                <img src="/images/email-icon.png"  className="input-icon"></img>
                  <input
                    type="email"
                    placeholder={t("email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-input input-with-icon auth-input"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="ch-form pos-relative">
                  <img src="/images/password-icon.png"  className="input-icon"></img>
                  <input
                    type="password"
                    placeholder={t("Password")}
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    className="form-input input-with-icon password-bg auth-input"
                  />
                </Form.Group>

                <div className="forgot-pass link-text">
                  <Link to="/forget-pass">{t("f-pass")}</Link>
                </div>
                <div className="my-3">
                  <button type="submit" onClick={login} className={"btn blue-btn capitalize"}>
                    {signInStart ? "PROCESSING..." : t("login-now")}
                  </button>
                </div>
                <Alert
                  show={errorMessage != null}
                  variant="danger"
                  className="mt-2"
                >
                  {errorMessage && errorMessage}
                </Alert>
              </Form>
              <div className="text-center mt-5 capitalize">{t("or-login-with")}</div>
              <div className="">
              <div className="social-login-div d-flex justify-content-between mt-3">
                <div><FacebookLogin
                  appId="649699255566506"
                  fields="name,email,picture"
                  callback={loginFacebook}
                  render={(renderProp) => {
                    return (
                      <div className="circle text-center social-circle">
                        <a onClick={renderProp.onClick}>
                         <img src="/images/icons/login-fb.svg"/>
                        </a>
                      </div>
                    );
                  }}
                /></div>
                <div>
                  <GoogleLoginComponent clientId={clientId}></GoogleLoginComponent>
                </div>
                <div>
                <AppleLogin
                        clientId="ventures.novum.stockhero"
                        redirectURI={`${host}/login`}
                        callback={loginApple}
                        responseType="code id_token"
                        responseMode="fragment"
                        render={(renderProp) => {
                          return (
                            <div className="circle text-center social-circle">
                              <a onClick={renderProp.onClick}>
                                <img src="/images/icons/Apple.svg"/>
                              </a>
                            </div>
                          );
                        }}
                      />
                  </div>
              </div>
              </div>

              <div className="text-center mt-4">
                
                <span className="mr-2">{t("no-acc")}</span>
                <Link to="/signup" className="link-text">{t("create-acc")}</Link>
              </div>
            </div>
          </Col>
        </Row>
    </GuestContainer>
      
    </>
  );
};
export default connect(null, {
  signIn,
  resetSignIn,
  signInGoogle,
  signInFacebook,
  signInApple,
  changeTimezone,
  getRegisterOtp,
})(Login);
