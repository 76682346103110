import React, { useEffect } from "react";
import {
  CHANGE_BO,
  CHANGE_BO_TYPE,
  CHANGE_EO,
  CHANGE_FREQUENCY,
} from "../../../../actions/create-bot-type";
import BooleanView from "../BooleanView";
import SliderView from "../SliderView";
import TutorialPopup from "../../../help/TutorialPopup";
import * as HelpContent from "../../../../utils/HelpContent";
import { useDispatch, useSelector } from "react-redux";
import { isLong } from "../../../../utils/Utils";
import { Frequencies, FrequencyValues,FrequencyValuesSimple,FrequenciesSimple,BotType, SUB_TIER, FrequencyValuesPro, FrequenciesPro } from "../../../../Const";
import { checkSub } from "../../../../utils/SubUtils";
import MinimumPriceGapModule from "../MinimumPriceGapModule";

const NormalParamsView = (props) => {
  const { newBot,t } = props;
  const orderType = newBot.baseOrderType;
  const extraOrders = newBot.extraOrderNum;
  const frequency = newBot.frequency;
  const baseOrder = newBot.baseOrder;
  const fund = newBot.initFund;
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const { subPremium, subTier } = checkSub(subscription);
  const tradingFreqValues = (subPremium && subTier === SUB_TIER.pro)?(newBot.botType==BotType.simple?FrequencyValues:FrequencyValuesPro):(newBot.botType==BotType.simple?FrequencyValuesSimple:FrequencyValues)
  const tradingFreqencies = (subPremium && subTier === SUB_TIER.pro)?(newBot.botType==BotType.simple?Frequencies:FrequenciesPro):(newBot.botType==BotType.simple?FrequenciesSimple:Frequencies)
  const isExitBot = newBot.botType==BotType.exit;
  const isSimpleBot = newBot.botType==BotType.simple;
  const dispatch = useDispatch();
  const tfValue = tradingFreqValues.indexOf(frequency) >0? tradingFreqValues.indexOf(frequency) : 0;
  const currentCoin = isLong(newBot.strategy)
    ? newBot.quoteCoin
    : newBot.baseCoin;

  const baseOrderChanged = (val) => {
    dispatch({
      type: CHANGE_BO,
      payload: val,
    });
  };

  const orderTypeChanged = (val) => {
    dispatch({
      type: CHANGE_BO_TYPE,
      payload: val,
    });
  };

  const extraOrdersChanged = (val) => {
    dispatch({
      type: CHANGE_EO,
      payload: val,
    });
  };

  const frequencyChanged = (val) => {
    dispatch({
      type: CHANGE_FREQUENCY,
      payload: val,
    });
  };

  const formatExtraOrder = (value) => {
    if (value == 0) {
      return t("disable");
    }
    return `${value} (${(100 - baseOrder) / value}%)`;
  };
  const formatBaseOrder = (value) =>
    `${((fund * value) / 100).toFixed(4)} ${currentCoin} (${value}%)`;
  const formartFrequency = (value) => tradingFreqencies[value];
  useEffect(() => {
      frequencyChanged(tradingFreqValues[tfValue])
  }, [tfValue]);
  return (
    <div>
      <div style={{ marginTop: 20 }}>
        {!isExitBot && <SliderView
          title={t("bo-limit")}
          step={5}
          min={5}
          formatLabel={formatBaseOrder}
          onChange={baseOrderChanged}
          value={baseOrder}
          tutorial={<TutorialPopup content={t("baseOrder-help")} />}
        /> }
      </div>
      <div style={{ marginTop: 20 }}>
      {!isExitBot &&<BooleanView
          title={t("bo-type")}
          firstButton="static"
          secondButton="dynamic"
          selected={orderType}
          onSelect={orderTypeChanged}
          tutorial={<TutorialPopup content={t("base-order-type-help")} />}
        />}
      </div>
      <div style={{ marginTop: 20 }}>
      {!isExitBot && <><SliderView
          title={t("eo")}
          min={0}
          max={isSimpleBot?10:30}
          value={extraOrders}
          formatLabel={formatExtraOrder}
          onChange={extraOrdersChanged}
          tutorial={<TutorialPopup content={t("extra-order-help")} />}
          
        /><MinimumPriceGapModule newBot={newBot} t={t}></MinimumPriceGapModule></> }
      </div>
      <div style={{ marginTop: 20 }}>
        <SliderView
          title={t("tf")}
          max={tradingFreqValues.length - 1}
          value={tfValue}
          formatLabel={formartFrequency}
          onChange={(value) => frequencyChanged(tradingFreqValues[value])}
          tutorial={<TutorialPopup content={t("trading-freq-help")} />}
        />
      </div>
    </div>
  );
};

export default NormalParamsView;
