import moment from "moment";
import { ZeroAmount } from "../Const";
import { eligibleBots } from "../actions/const";

export const capitalize = (text) => {
  return (text && text.charAt(0).toUpperCase() + text.slice(1)) || "";
};

export const formatNumberToString = (num, decimal = 8) => {
  if (!num) return ZeroAmount;
  return num.toFixed(decimal);
};

export const roundNumber = (num, scale) => {
  var scale = scale || scaleNumber(num);
  if (!("" + num).includes("e")) {
    return +(Math.round(num + "e+" + scale) + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = "";
    if (+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(
      Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) +
      "e-" +
      scale
    );
  }
};

export const scaleNumber = (num) => {
  if (num == 0) return 0;
  const scale = 4 - Math.floor(Math.log10(Math.abs(num)));
  return scale < 1 ? 1 : scale;
};

export const formatDate = (
  dateString,
  from = "YYYY-MM-DD HH:mm:ss",
  to = "DD MMM YYYY"
) => {
  const timezone = localStorage.getItem("timezone");
  const date = moment.utc(dateString, from);
  return timezone
    ? date.utcOffset(timezone).format(to)
    : date.local().format(to);
};
export const formatDateTime = (
  dateString,
  from = "YYYY-MM-DD HH:mm:ss",
  to = "DD MMM YYYY HH:mm:ss"
) => {
  const timezone = localStorage.getItem("timezone");
  const date = moment.utc(dateString, from);
  return timezone
    ? date.utcOffset(timezone).format(to)
    : date.local().format(to);
};

export const milisecOf = (dateString, format = "YYYY-MM-DD HH:mm:ss") => {
  const date = moment.utc(dateString, format);
  return date.valueOf();
};

export const compareDate = (date1, date2) => {
  return moment(date1).isBefore(date2);
};

export const dayTillNow = (dateString, format = "YYYY-MM-DD HH:mm:ss") => {
  const date = moment.utc(dateString, format);
  const ms = moment().diff(date);
  return normalizeTime(ms);
};

export const getCurrentTimeZone = () => {
  const offset = moment().utcOffset();
  const min = offset % 60;
  const absMin = Math.abs(min);
  const hour = (offset - min) / 60;
  const absHour = Math.abs(hour);
  return `${hour < 0 ? "-" : "+"}${absHour < 10 ? "0" + absHour : absHour}:${
    absMin < 10 ? "0" + absMin : absMin
  }`;
};

export const normalizeTime = (ms) => {
  const day = ms / 1000 / 60 / 60 / 24;
  return { time: day, unit: "D" };
};

export const decimalOf = (coin) => {
  const lowerCase = coin.toLowerCase();
  return lowerCase === "btc" ? 6 : lowerCase.includes("usd") ? 2 : 4;
};

export const normalizeNumber = (num) => {
  const numString = String(num) || "0";
  return parseFloat(numString.replace(",", ".")) || 0;
};

export const typeNameOrder = (type) => {
  switch (type.toLowerCase()) {
    case "de":
      return "Liquidated";
    case "bo":
      return "Base Order";
    case "eo":
      return "Extra Order";
    case "tp":
      return "Take Profit";
    case "sl":
      return "Stoploss";
    case "ie":
      return "Indicator Exit";
    default:
      return "Order";
  }
};

export const orderSide = (type, isLong) => {
  
  const lowerCaseType = type.toLowerCase();
  if (
    (isLong && ["bo", "eo"].includes(lowerCaseType)) ||
    (!isLong && ["de", "sl", "tp","ie"].includes(lowerCaseType))
  )
    return "Buy";
  return "Sell";
};

export const getResource = (path) => {
  const components = path && path.split("/");
  return (
    components && components.length > 1 && components[components.length - 1]
  );
};

export const divZeroable = (a, b) => {
  return b == 0 ? 0 : a / b;
};

export const isLong = (strategy) => {
  if (!strategy) return true;
  return strategy.toLowerCase() === "long";
};
export const isMarginShort = (strategy) => {
  if (!strategy) return true;
  return strategy.toLowerCase() === "margin short";
};

export const hideEmail = (email) => {
  if (!email) return "************";
  const firstChar = email[0];
  return firstChar + "***********";
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const getMonthDay = (day) => {
  switch (day) {
    case 1:
    case 21:
    case 31:
      return day + "st";
    case 2:
      return "2nd";
    case 3:
      return "3rd";
    default:
      return day + "th";
  }
};
export const  restrictAlphabets = (e)=>{
  var x = e.which || e.keycode;
  if(!(x>=46 && x<=57))
    e.preventDefault()
}
export const trimString = (str,length)=>{
  return str.length > length ? str.substring(0, length) + "..." : str;
}
export const currencyFormat = (number)=>{
  return new Intl.NumberFormat().format(number)
}
export const eligibleExtendedHours = (selectedExchange)=>{
  const exchangeIds = [0,1,2,16,17];
  return exchangeIds.includes(selectedExchange.id)
}
export const disableFractionExchanges = (selectedExchange)=>{
  const exchangeIds = [1,2,5,8];
  return exchangeIds.includes(selectedExchange.id)
}
