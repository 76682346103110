import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getDailyPnl } from "../../actions/dashboard-action";
import { getBotProfit } from "../../actions/bot-action";

const PnlData = (props)=>{
    const {t,exchangeId, getBotProfit} = props
    const profit = useSelector((state) => state.bots.profit);
    const currency = useSelector((state) => state.settings.currency) || "USD";
    const changeUp = profit && profit.current_wl_ratio > 0;
    const dailyPnl = useSelector((state) => state.dashboard.dailyPnl) || {};
    const allPnL = (dailyPnl.pnl && dailyPnl.pnl.fiat) || "0";
    // useEffect(() => {
    //     getBotProfit(interval, exchangeId);
    //   }, [exchangeId, interval]);
    return (
        <div className="row">
        <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 v-pad">
                        <p className="small-text">{t("real-pnl")}</p>
                        <h2>
                        {profit && profit.current_profit.toFixed(2)}{" "}
              {currency && currency.name}
                        </h2>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 v-pad">
                        <p className="small-text">{t("w-l-ratio")}</p>
                        <h2 className={changeUp ? "upward-change" : "downward-change"}>
                        {changeUp?<i className="fas fa-arrow-up"></i>:<i className="fas fa-arrow-down"></i>}
                        {" "}
                        {profit && profit.current_wl_ratio}%
                        </h2>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 v-pad">
                        <p className="small-text">
                        {t("deals")}
                        </p>
                        <h2>
                        {profit && profit.trade_number}
                        </h2>
                    </div>
                    {/* <div className="col-xl-1 col-lg-2 col-md-6 col-sm-6 v-pad">
                      <p className="small-text">APY</p>
                      <h2>{dailyPnl.apy}%</h2>
                    </div> */}
                    <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 v-pad">
                      <p className="small-text">All Time PnL</p>
                      <h2>
                        {allPnL} {currency && currency.name}
                      </h2>
                    </div>
        </div>
    )
}

export default connect(null,{getBotProfit}) (PnlData);