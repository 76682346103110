import React, { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, InputGroup } from "react-bootstrap";

import TutorialPopup from "../../help/TutorialPopup";
import * as HelpContent from "../../../utils/HelpContent";
import { CHANGE_ORDER_TYPE_EXIT_RENT, CHANGE_ORDER_TYPE_RENT, CHANGE_RENT_FUND, CLOSE_DEAL_END_OF_TRADING_DAY_RENT } from "../../../actions/create-bot-type";
import { isLong, isMarginShort, restrictAlphabets } from "../../../utils/Utils";
import { formatNumberToString } from "../../../utils/Utils";
import LeftRightForm from "../../bots/new-bot/modules/LeftRightForm";
import CustomCheckbox from "../../bots/new-bot/modules/CustomCheckbox";
import ExtendedTradingHours from "../../bots/new-bot/modules/ExtendedTradingHours";
import TwentyFourHoursTrading from "../../bots/new-bot/modules/TwentyFourHoursTrading";
import DisableFractionalPurchase from "../../bots/new-bot/modules/DisableFractionalPurchase";
import BooleanView from "../../bots/new-bot/BooleanView";

const TradeParamsViewRent = (props) => {
  const { editing,t } = props;
  const rental = useSelector((state) => state.createBot.rentBot.rental);
  const rentBot = useSelector((state) => state.createBot.rentBot);
  const endDayDealClose = rentBot.closeDealEndOfTradingDay?true:false;
  const fund = rentBot.initFund;
  const strategy = editing?rentBot.strategy:rentBot.strategy?rentBot.strategy:rental && rental.template.strategy
  const currentCoin = (isLong(editing?rentBot.strategy:rental && rental.template.strategy) || isMarginShort(strategy))
    ? rentBot.quoteCoin
    : rentBot.baseCoin;
  const balances = useSelector((state) => state.bots.balances);
  const pairData = useSelector((state) => state.bots.pairData);
  const balance =
    balances && balances.find((item) => item.coin === currentCoin);
  const dispatch = useDispatch();
  useEffect(() => {
    if (balance && !editing && !rentBot.initFund) {
      //fundChanged(parseFloat(balance.free).toFixed(2));
    }
  }, [balance]);

  const fundChanged = (val) => {
    dispatch({
      type: CHANGE_RENT_FUND,
      payload: val,
    });
  };
  const setAutoCloseDealValue = (val) => {
    dispatch({
      type: CLOSE_DEAL_END_OF_TRADING_DAY_RENT,
      payload: val?1:0
    });
  };
  const orderTypeChanged = (orderType) => {
    dispatch({
      type: CHANGE_ORDER_TYPE_RENT,
      payload: orderType,
    });
  };
  const exitOrderTypeChanged = (orderType) => {
    dispatch({
      type: CHANGE_ORDER_TYPE_EXIT_RENT,
      payload: orderType,
    });
  };

  return (
    <>
      <LeftRightForm
        left={<><Form.Label>
          {t("fund-alloc")} <TutorialPopup content={t("initFund-help")} />
          </Form.Label></>}
        right={<><label data-text={currentCoin} className="input-gp-label">
        <Form.Control
            as="input"
            type="number"
            placeholder="1000"
            value={fund}
            onChange={(e) => {
              fundChanged(e.target.value);
            }}
            onKeyPress={(e) => {
              restrictAlphabets(e);
            }}
            className="input-text input-gp-text"
          ></Form.Control>
      </label>
      <p style={{ fontSize: 13, marginTop: 10 }}>
                {pairData && pairData.native_coin !='USD' ? <>{t("available")}: {formatNumberToString(balance && balance.free,2)}{" "} 
                {currentCoin} {", "} {formatNumberToString(pairData && pairData.native_balance)}{" "} {pairData && pairData.native_coin} (~{pairData && (pairData.native_balance * pairData.native_quote_rate)} {" "} USD)</>:<>{t("available")}: {formatNumberToString(balance && balance.free,2)}{" "} 
                {currentCoin} {" "}</>} </p>
      </>}
      ></LeftRightForm>
      <div style={{ marginTop: 20 }}>
            <BooleanView
                title={t("entry-order-type")}
                firstButton="limit"
                secondButton="market"
                selected={rentBot.tradeOrderType}
                onSelect={orderTypeChanged}
                tutorial={<TutorialPopup content={t("orderType")} />}
              />
           
            </div>
            <div style={{ marginTop: 20 }}>
               <BooleanView
                title={t("exit-order-type")}
                firstButton="limit"
                secondButton="market"
                selected={rentBot.tradeOrderTypeExit}
                onSelect={exitOrderTypeChanged}
                tutorial={<TutorialPopup content={t("orderType")} />}
              />
            </div>
      <LeftRightForm 
        right={<div className="d-flex align-items-center dis-bot-stop-loss mt-3">
                 
        <div style={{width:"100%"}}>
        <CustomCheckbox 
          val={endDayDealClose} 
          checked={endDayDealClose} 
          callback={setAutoCloseDealValue}
          label={t("auto-close-trading")}
          id={'auto-close-trading'}
          >
      </CustomCheckbox>
        </div>
      
      </div>}
      ></LeftRightForm>
            
      
        <ExtendedTradingHours rent={true}></ExtendedTradingHours>
        {rentBot.is247 && 
        <TwentyFourHoursTrading rent={true}></TwentyFourHoursTrading> }
        <DisableFractionalPurchase rent={true}></DisableFractionalPurchase>
            
          
    </>
  );
};

export default connect(null)(TradeParamsViewRent);
