import React, { useEffect } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import {  SELECT_EXCHANGE } from "../../actions/types";
import { getExchanges, selectExchange } from "../../actions/setting-action";
import { loadExchangePairs } from "../../actions/bot-action";

const ExchangeDropDown = (props)=>{

    const {showExchange, getExchanges, selectExchange,url,loadExchangePairs } = props;
    var exchanges = useSelector((state) => state.settings.exchanges) || [];
    const exchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
    const dispatch = useDispatch();
    exchanges = exchanges.filter((item) => item.is_connected || item.id === 0);
    exchanges.sort((el1, el2) => el1.order - el2.order)
    const selectedExchange = useSelector(
        (state) => state.settings.selectedExchange
    );
    
    const changeExchange = (id)=>{
        selectExchange(id);
        loadExchangePairs(id).then((code)=>{
          if(code==200){
            //window.location.reload(false);
          }
        }).catch((err)=>{
    
        })
        //getExchangePairs(id,true)
        //
      }

      useEffect(() => {
        getExchanges();
      }, []);
      useEffect(() => {
        const exchange =
          (exchanges && exchanges.find((item) => item.id === exchangeId)) || {};
          if (Object.keys(exchange).length > 0) {
            dispatch({
              type: SELECT_EXCHANGE,
              payload: exchange,
            });
          }
      }, [exchanges, exchangeId]);
    
      const exchangeItems =
        exchanges &&
        exchanges.map((item) => {
          return (
            <span key={item.id} onClick={()=>changeExchange(item.id)}>

            <a ><img src={item.image} alt="" width={20}/> &nbsp;&nbsp;&nbsp;{item.name}</a>
            <br></br>
            </span>
          );
        });
    //console.log(exchangeItems)
    return (
        <>
            <div className="header-menu">
            <div className="dropdown">
              <div className="header-btn"><img src={selectedExchange.id==0?"/images/icons/paper.svg": selectedExchange.image} alt="" width={20}/> &nbsp;{(selectedExchange && selectedExchange.name) || "Paper"} &nbsp;&nbsp;&nbsp;<i className="fa fa-angle-down"></i></div>
              <div className="dropdown-content">
                <div className="dropdown-triangle"></div>
                <div className="dropdown-list exchanges">
                  {exchangeItems}
                </div>
              </div>
            </div>
          </div>
        </>
    )
}

export default connect(null,{getExchanges,loadExchangePairs,selectExchange})(ExchangeDropDown)