import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getDashboardAssets, resetPagination } from "../../actions/dashboard-action";
import PortfolioChart from "./PortfolioChart";
import ChartTimeFrames from "./ChartTimeFrames";
import SpotOverview from "./SpotOverview";
import DailyPnlChart from "./DailyPnlChart";
import { RESET_ALL_PAGINATION } from "../../actions/types";


const DashboardOverview = (props) => {
  const { t, getDashboardAssets,resetPagination } = props;
  const dailyPnl = useSelector((state) => state.dashboard.dailyPnl) || {};
  const currency = useSelector((state) => state.settings.currency);
  const allPnL = (dailyPnl.pnl && dailyPnl.pnl.fiat) || "0";
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const [timeframe, setTimeframe] = useState("1M");
  const [showGraph, setShowGraph] = useState(false);
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;

  useEffect(() => {
    getDashboardAssets(selectedExchangeId);
  }, [timeframe, selectedExchangeId]);
  useEffect(() => {
    resetPagination()
  }, []);

  const toggleGraph = () => {
    setShowGraph(!showGraph);
  };
  
  
  return (
    <>
      <div className="chart-container">
        <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
          <div className="panel">
            <div className="panel-heading " role="tab" id="headingOne">
              <div className="panel-title">
                <div className="chart-arrow">
                  <a
                  
                    role="button"
                    data-toggle="collapse"
                    data-target="#graphCollapse"
                    aria-expanded="false"
                    aria-controls="graphCollapse"
                    onClick={toggleGraph}
                  ><i className="fas fa-chevron-down"></i></a>
                  </div>
                  <div className="row">
                    
                    <SpotOverview data={{ t,
                        currency,
                        timeframe
                        }}/>
                    {/* <div className="col-xl-1 col-lg-2 col-md-6 col-sm-6 v-pad">
                      <p className="small-text">APY</p>
                      <h2>{dailyPnl.apy}%</h2>
                    </div> */}
                    <div className="col-xl-4 col-lg-3 col-md-12 col-sm-12 v-pad">
                      <p className="small-text">All Time PnL</p>
                      <h2>
                        {allPnL} {currency && currency.name}
                      </h2>
                    </div>
                  </div>
              </div>
            </div>
            <div className={`transition-div ${showGraph ? "show" : ""}`} id="graphCollapse">
              <div className="panel-body">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 v-pad chart-graph">

                    <PortfolioChart data={{timeframe,selectedExchangeId}}/>
                    <ChartTimeFrames timeframe={timeframe} setTimeframe={setTimeframe}/>
                    
                    <div className="graph-desc">
                      <ul className="green-bullet">
                        <li>Portfolio Value</li>
                      </ul>
                      <ul>
                        <li>Trading Volume</li>
                      </ul>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                  <div className="col-xl-6 col-lg-6 v-pad chart-bar">
                    <DailyPnlChart data={{selectedExchangeId,theme}}/>
                    <p className="small-text">Daily PnL</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getDashboardAssets,resetPagination })(DashboardOverview);



