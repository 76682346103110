import {
  BOT_LIST,
  BOT_DETAIL,
  GET_SUBSCRIPTION,
  GET_BALANCE,
  BOT_PROFIT,
  GET_PAIRS,
  GET_INDICATORS,
  BOT_RENT_LIST,
  RENT_BOT_DETAIL,
  GET_ACCOUNT_PAIR,
  PRICE_ALERT_PAIR,
  SELECT_EXCHANGE,
  SET_DAISY_CHAIN_BOTS,
} from "./types";
import { CHANGE_EXCHANGE, CHANGE_PAIR, CHANGE_RENT_PAIR }  from "../actions/create-bot-type";
import { subscription } from "../mocks/subscriptions-mock";

import {
  botDetail,
  balanceUrl,
  botListUrl,
  updateBotStatusUrl,
  deleteBotUrl,
  botProfitUrl,
  pairListUrl,
  indicatorsUrl,
  templateRentUrl,
  rentDetailUrl,
  thumbsUpUrl,
  pairBalanceUrl,
  forceOrderUrl,
  updateBotUrl,
  daisyChainUrl,
} from "./const";

import network from "./network";

export const getBots = (exchangeId,pageSize,pageNum,status,orderBy,sort) => (dispatch, getState) => {
  let params = {
    exchange_id: exchangeId,
    page_size: pageSize,
    page: pageNum,
    status: status,
    sort_direction: orderBy?orderBy:"",
    sort_by: sort?sort:"",
  };

  network.get(botListUrl, params, dispatch, getState).then((res) => {
    dispatch({
      type: BOT_LIST,
      payload: {
        bots:res.data.bots.data,
        totalBots:res.data.bots.total,
        startItem:pageNum?res.data.bots.from:1,
        pageNum:pageNum?pageNum:1,
        status:status
      }
    });
    
  });
};
export const getRentalBots = (exchangeId,pageSize,pageNum,status,orderBy,sort) => (dispatch, getState) => {
  let params = {
    exchange_id: exchangeId,
    page_size: pageSize,
    page: pageNum,
    status: 'renting',
    sort_direction: orderBy?orderBy:"",
    sort_by: sort?sort:"",
  };

  network.get(templateRentUrl, params, dispatch, getState).then((res) => {
    dispatch({
      type: BOT_RENT_LIST,
      payload: {
        rents:res.data.rents.data,
        totalBots:res.data.rents.total,
        startItem:res.data.rents.from,
        pageNum:pageNum
      }
    });
    
  });
};
export const deleteBot = (id,exchangeId,pageSize,pageNum,selectedTab) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(
        deleteBotUrl,
        {
          id,
          force: 1,
        },
        dispatch,
        getState
      )
      .then((res) => {
        const { code, message } = res.data;
        if (code == 200) {
          dispatch(getBots(exchangeId,pageSize,pageNum,selectedTab));
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const forceEnterOrder = (botId,orderType) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(
        updateBotUrl+botId+forceOrderUrl,
        {
          order_name: orderType,
        },
        dispatch,
        getState
      )
      .then((res) => {
        const { code, message } = res.data;
        if (code == 200) {
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const activeBot = (id, status,exchangeId,pageSize,pageNum,selectedTab,orderBy,sort) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(
        updateBotStatusUrl,
        {
          id,
          status,
        },
        dispatch,
        getState
      )
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          dispatch(getBots(exchangeId,pageSize,pageNum,selectedTab,orderBy,sort));
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const getSubscription = () => (dispatch) => {
  setTimeout(() => {
    dispatch({
      type: GET_SUBSCRIPTION,
      payload: subscription,
    });
  }, 1000);
};

export const getBotDetail = (id) => (dispatch, getState) => {
  dispatch({
    type: BOT_DETAIL,
    payload: {},
  });
  return new Promise((resolve, reject) => {
    network
      .get(
        botDetail,
        {
          id,
        },
        dispatch,
        getState
      )
      .then((res) => {
        const { code, message, bot } = res.data;
        if (code === 200) {
          dispatch({
            type: BOT_DETAIL,
            payload: bot,
          });
          resolve(bot);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const getExchangePairs = (exchangeId,reload) => (dispatch, getState) => {
  network
    .get(pairListUrl, { exchange_id: exchangeId,default:1 }, dispatch, getState)
    .then((res) => {
      const { usd,exchange } = res.data;
      dispatch({
        type: GET_PAIRS,
        payload: {
          usdPairs: usd,
          btcUsdtPair: usd[0],
        },
      });
      dispatch({
        type: CHANGE_PAIR,
        payload: usd[0]
      });
      dispatch({
        type: CHANGE_RENT_PAIR,
        payload: usd[0]
      });
      dispatch({
        type: PRICE_ALERT_PAIR,
        payload: usd[0]
      });
      dispatch({
        type: CHANGE_EXCHANGE,
        payload: exchange
      });
      dispatch({
        type: SELECT_EXCHANGE,
        payload: exchange
      });
      if(reload)
      window.location.reload();
    });
};
export const loadExchangePairs = (exchangeId,reload) => (dispatch, getState) => {
  return new Promise((resolve,reject)=>{
    network
    .get(pairListUrl, { exchange_id: exchangeId,default:1 }, dispatch, getState)
    .then((res) => {
      const { usd,exchange,code } = res.data;
      dispatch({
        type: GET_PAIRS,
        payload: {
          usdPairs: usd,
          btcUsdtPair: usd[0],
        },
      });
      dispatch({
        type: CHANGE_PAIR,
        payload: usd[0]
      });
      dispatch({
        type: CHANGE_RENT_PAIR,
        payload: usd[0]
      });
      dispatch({
        type: PRICE_ALERT_PAIR,
        payload: usd[0]
      });
      dispatch({
        type: CHANGE_EXCHANGE,
        payload: exchange
      });
      dispatch({
        type: SELECT_EXCHANGE,
        payload: exchange
      });
      resolve(code)
      //console.log("pairs",res)
      
    });
  })
  
};

export const searchExchangePair = (exchangeId,symbol) => (dispatch, getState) => {
  return new Promise((resolve,reject)=>{
      network
      .get(pairListUrl, { exchange_id: exchangeId,symbol:symbol }, dispatch, getState)
      .then((res) => {
        const { usd,exchange,code } = res.data;
        resolve(usd)
      })
  })  
  
  
};

export const getIndicators = () => (dispatch, getState) => {
  network.get(indicatorsUrl, {}, dispatch, getState).then((res) => {
    const { indicators } = res.data;
    dispatch({
      type: GET_INDICATORS,
      payload: {
        indicators,
      },
    });
  });
};
export const getDaisyChainBots = (exchangeId) => (dispatch, getState) => {
  network.get(daisyChainUrl, {exchange_id: exchangeId}, dispatch, getState).then((res) => {
    const { bots } = res.data;
    dispatch({
      type: SET_DAISY_CHAIN_BOTS,
      payload: bots
    });
  });
};

export const getBalance = (exchangeId) => (dispatch, getState) => {
  network
    .get(
      balanceUrl,
      {
        exchange_id: exchangeId,
      },
      dispatch,
      getState
    )
    .then((res) => {
      const { code, exchange } = res.data;
      if (code == 200) {
        dispatch({
          type: GET_BALANCE,
          payload: res.data.exchange.user_balances,
        });
      } else {
        dispatch({
          type: GET_BALANCE,
          payload: 0,
        });
      }
    });
};
export const getPairBalance = (exchangeId,pairId,account) => (dispatch, getState) => {
  network
    .get(
      pairBalanceUrl,
      {
        exchange_id: exchangeId,
        pair_id: pairId,
        account: account,
      },
      dispatch,
      getState
    )
    .then((res) => {
      const { code } = res.data;
      if (code == 200) {
        dispatch({
          type: GET_ACCOUNT_PAIR,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_ACCOUNT_PAIR,
          payload: {},
        });
      }
    });
};

export const getBotProfit = (timeFrame, exchangeId) => (dispatch, getState) => {
  network
    .get(
      botProfitUrl,
      {
        time_frame: timeFrame,
        exchange_id: exchangeId,
      },
      dispatch,
      getState
    )
    .then((res) => {
      const { current_profit, current_wl_ratio, trade_number, histories } =
        res.data;
      dispatch({
        type: BOT_PROFIT,
        payload: {
          current_profit,
          current_wl_ratio,
          trade_number,
          histories,
        },
      });
    });
};
export const getRentBotDetail = (id) => (dispatch, getState) => {
  dispatch({
    type: RENT_BOT_DETAIL,
    payload: {},
  });
  return new Promise((resolve, reject) => {
    network
      .get(
        rentDetailUrl+id,
        {},
        dispatch,
        getState
      )
      .then((res) => {
        const { code, message, rent } = res.data;
        if (code === 200) {
          dispatch({
            type: RENT_BOT_DETAIL,
            payload: rent,
          });
          resolve(rent);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};
export const botFeedback = (value,templateId,exchangeId,pageSize,pageNum) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(
        thumbsUpUrl,
        {
          template_id:templateId,
          value:value==0?1:0,
        },
        dispatch,
        getState
      )
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          dispatch(getRentalBots(exchangeId,pageSize,pageNum));
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};