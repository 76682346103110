import React, { useEffect, useState } from "react";
import { Col, Dropdown, DropdownButton } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import Loading from "../common/Loading";
import BotTab from "./BotTab";
import BotTabRent from "./BotTabRent";
import useLoading from "../hooks/useLoading";
import { getBotDetail, getBots, getIndicators, getRentalBots } from "../../actions/bot-action";
import { resetBotConfig, updateCopyBotConfig, updateEditBotConfig, updateEditRentBotConfig } from "../../actions/create-bot-action";
import { useHistory, useLocation } from "react-router-dom";
import { CREATE_BOT_START } from "../../actions/create-bot-type";
import queryString from "query-string";
import ReactStatePagination from "../common/ReactStatePagination";
import { BOTS_ACTIVE_TAB, BOTS_ORDER_BY, BOTS_SORT } from "../../actions/types";
import { toast } from "react-toastify";


const BotList  = (props)=>{
    const {t,getBots,getRentalBots,getBotDetail,updateCopyBotConfig,updateEditBotConfig,getIndicators,resetBotConfig,updateEditRentBotConfig} = props
    const tabTitles = [t("active"), t("inactive"),t("rent")];
    const statusArray = ['active','inactive','renting'] 
    const { search } = useLocation();
    const query = queryString.parse(search)
   
    const [setLoading, bindLoading] = useLoading(false);
    const history = useHistory()
    const dispatch = useDispatch()
    const [selectedTab, getSelectedTab] = useState('active');
    var bots = useSelector((state) => state.bots.bots) || [];
    var rents = useSelector((state) => state.bots.rents) || [];
    const totalBots = useSelector((state) => state.bots.totalBots) || 0;
    const startItem = useSelector((state) => state.bots.startItem) || 1;
    const totalRentBots = useSelector((state) => state.bots.totalRentBots) || 0;
    const startItemRent = useSelector((state) => state.bots.startItemRent) || 1;
    const selectedExchangeId = useSelector((state) => state.settings.selectedExchangeId) || 0;
    const activeTab = useSelector((state) => state.pagination.botsActiveTab) || 0;
    const sort = useSelector((state) => state.pagination.botSort) || "name";
    const orderBy = useSelector((state) => state.pagination.botsOrderBy) || "desc";
    const pageNumbers = {
      "active":useSelector((state) => state.pagination.botsActivePage),
      "inactive":useSelector((state) => state.pagination.botsInactivePage),
      "rent":useSelector((state) => state.pagination.botsRentPage),
    }
    bots = bots.filter((item) => {
        return selectedExchangeId == item.exchange_id;
      });
    const status = statusArray[activeTab]
    const Sorts = { name: t("NAME"),apy: "APY",created_at:t("DATE"),performance:t("PERFORMANCE"),profit:t("RETURN")};
    const OrderBy = { desc: "DESC", asc: "ASC" };
    const itemsPerPage = 10;
    const tourClasses = ["active-bots-tour", "inactive-bots-tour","rented-bots-tour"];
    const sortOptions = Object.keys(Sorts).map((el) => {
      return (
        <Dropdown.Item key={el} eventKey={el} className="text-left">
          {Sorts[el]}
        </Dropdown.Item>
      );
    });
    const orderByOptions = Object.keys(OrderBy).map((el, id) => {
      return (
        <Dropdown.Item key={el} eventKey={el} className="text-left">
          {OrderBy[el]}
        </Dropdown.Item>
      );
    });
    const setActiveTab = (index)=>{
        dispatch({
          type:BOTS_ACTIVE_TAB,
          payload:index
        })
    }
    if (query.rental) {
      setActiveTab(2)
    }
   
    const setSort = (sort)=>{
      dispatch({
        type:BOTS_SORT,
        payload:sort
      })
    }
    const setOrderBy = (sort)=>{
      dispatch({
        type:BOTS_ORDER_BY,
        payload:sort
      })
    }
    const tabHeaders = tabTitles.map((item, index) => {
      return (
          
          <a
              className={(index === activeTab ? "list-tab tab-active" : "list-tab tab-inactive") + " "+ tourClasses[index]}
              onClick={() => changeTab(index,index==0?'active':'inactive')}
              key={index}
          >
              {item}
          </a>
         
      );
  });
  const paginationCallback = (pageNum)=>{
    getBots(selectedExchangeId,itemsPerPage,pageNum,status,orderBy,sort);
  }
  const paginationCallbackRent = (pageNum)=>{
    getRentalBots(selectedExchangeId,itemsPerPage,pageNum,status,orderBy,sort)
  }
    const botAction = (botId,action) => {
      setLoading(true);
      getBotDetail(botId)
        .then((res) => {
          if(!res.rent_id){
            if(action==="edit"){
                updateEditBotConfig();
            }
            if(action==="copy"){
              updateCopyBotConfig(botId);
            }
            dispatch({
              type: CREATE_BOT_START,
              payload: true,
            });
            history.push(`/bots/create`);
          }
          else{
            if(action==="edit"){
              dispatch({
                type: CREATE_BOT_START,
                payload: true,
              });
              updateEditRentBotConfig()
              history.push(`/rent/create`);
            }
            else{
              toast.error("Not available for rental bots");
            }
          }
            setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          //toast.error(err);
        });
    };
    const tabs = [
        <Loading {...bindLoading}>
        <BotTab className="active-bots-tour"
          bots={bots}
          startItem={startItem}
          itemsPerPage={itemsPerPage}
          pageNum={pageNumbers[status]}
          selectedTab={selectedTab}
          active={true}
          botAction={botAction}
          t={t}
        ></BotTab></Loading>,
        <Loading {...bindLoading}><BotTab className="inactive-bots-tour"
          bots={bots}
          startItem={startItem}
          itemsPerPage={itemsPerPage}
          pageNum={pageNumbers[status]}
          selectedTab={selectedTab}
          active={false}
          botAction={botAction}
          //onShowBotEdit={onShowBotEdit}
          t={t}
        ></BotTab></Loading>,
        <Loading {...bindLoading}><BotTabRent className="rented-bots-tour"
          bots={rents}
          startItem={startItemRent}
          itemsPerPage={itemsPerPage}
          selectedExchangeId={selectedExchangeId}
          pageNum={pageNumbers[status]}
          selectedTab={selectedTab}
          active={false}
          //onShowBotDetail={onShowBotDetail}
          t={t}
        ></BotTabRent></Loading>,
      ];
    const changeTab = (index,tab) => {
        setActiveTab(index);
        getSelectedTab(tab)
        if(index==2){
          getRentalBots(selectedExchangeId,itemsPerPage,pageNumbers[tab],tab,orderBy,sort)
        }
        else{
          getBots(selectedExchangeId,itemsPerPage,pageNumbers[tab],tab,orderBy,sort)
        }
        
    };
    useEffect(() => {
      if(activeTab !=2){
        getBots(selectedExchangeId,itemsPerPage,pageNumbers[status],status,orderBy,sort);
      }
      getRentalBots(selectedExchangeId,itemsPerPage,pageNumbers[status],selectedTab,orderBy,sort);
      getIndicators();
      resetBotConfig();
    }, [sort,orderBy,selectedExchangeId]);
    useEffect(() => {
      if (search != "") {
        if (query.rental) {
          setActiveTab(2)
        }
      } 
    }, []);
    return(
        <>
        <div className="d-flex justify-content-between">
            <div className="bot-tabs">
            {tabHeaders}
            </div>
            <div className="d-flex justify-content-between mt-20">
                  <div className="" >
                  <DropdownButton
                    title={Sorts[sort]}
                    onSelect={(e) => setSort(e)}
                    variant=""
                    className="deals-filter-btn"
                  >
                    {sortOptions}
                  </DropdownButton>
                  </div>
                <div className="" >
                  <DropdownButton
                    title={OrderBy[orderBy]}
                    onSelect={(e) => setOrderBy(e)}
                    variant=""
                    className="deals-filter-btn"
                  >
                    {orderByOptions}
                  </DropdownButton>
                </div>
                
        </div>
            
        </div>
        <div className="mt-4">
              {tabs[activeTab]}
        </div>
        <div className="bottom-center">
          {activeTab != 2 ? ( <ReactStatePagination
            itemsCountPerPage={itemsPerPage}
            forPage="bots"
            startItem={startItem}
            selectedTab={selectedTab}
            totalCount={totalBots}
            onChange={paginationCallback}
            itemsPerPage={itemsPerPage}
          /> ) : 
          (<ReactStatePagination
            itemsCountPerPage={itemsPerPage}
            forPage="bots"
            selectedExchangeId={selectedExchangeId}
            startItem={startItemRent}
            selectedTab={selectedTab}
            totalCount={totalRentBots}
            onChange={paginationCallbackRent}
            itemsPerPage={itemsPerPage}
          />) }
        </div>
       
        
        </>
    )
}

export default connect(null,{getBots,getRentalBots,getBotDetail,updateCopyBotConfig,updateEditBotConfig,resetBotConfig,getIndicators,updateEditRentBotConfig}) (BotList);